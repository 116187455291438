

























import {Component, Prop} from 'vue-property-decorator';
import {readHasReviewerAcccess} from '@/store/main/getters';
import TestResultItem from '@/components/TestResultItem.vue';
import TestResultComments from '@/components/TestResultComments.vue';
import ConfirmTestResultApproval from '@/components/modals/ConfirmTestResultApproval.vue';
import AppComponent from '@/mixins/ComponentMixin.vue';
import {IRequisition} from '@/interfaces/requisitions';
import {dispatchGetRequisitionsForReviewerById} from '@/store/crud/actions';

@Component({
  components: {
    TestResultItem,
    TestResultComments,
    ConfirmTestResultApproval,
  },
})
export default class TestResult extends AppComponent {
  public comments: string = '';
  public tab = 0;
  public panelIndex = 0;
  public requisitionOnView: IRequisition = {} as IRequisition;
  public isReviewer: boolean = false;

  @Prop({type: String, required: true}) public requisitionId: string;

  /**
   * created hook,
   * it calls async fetch data
   */
  public created() {
    this.fetchData();
  }

  public get requisition() {
    if (this.requisitionOnView && Object.keys(this.requisitionOnView).length > 0) {
      return this.requisitionOnView;
    } else {
      return false;
    }
  }

  /**
   * fetches data to build the view
   * asyncrhonous and displays loading screen
   */
  public async fetchData() {
    this.isReviewer = (await readHasReviewerAcccess(this.$store)) || false;

    if (!this.isReviewer) {
      // user is not available or not reviewer
      this.$toast.error('You are not authorized to view this page');
      this.$router.back();
      return;
    }

    if (!this.requisitionId) {
      this.$toast.error('Requisition ID is required');
      this.$router.back();
      return;
    }

    this.setAppLoading(true);
    this.comments = '';

    const requisitionFromId = await dispatchGetRequisitionsForReviewerById(
      this.$store,
      this.requisitionId,
    );

    if (requisitionFromId && requisitionFromId.length > 0) {
      this.requisitionOnView = requisitionFromId[0];
    } else {
      this.$toast.error('Requisition not found');
      this.$router.back();
    }
    this.setAppLoading(false);
  }
}
